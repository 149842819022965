import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import Seo from 'components/Seo';
import HomeHero from 'components/HomeHero';
import Stats from 'components/Stats';
import Values from 'components/Values';
import Skills from 'components/Skills';
import Lawyers from 'components/Lawyers';
import ArticlesCarousel from 'components/ArticlesCarousel';

const Homepage = ({ data }) => {
  const homepage = data?.datoCmsHomepage;
  const articles = data?.allDatoCmsArticle?.edges || [];

  return (
    <>
      <Seo
        title={homepage?.seo?.title}
        description={homepage?.seo?.description}
      />
      <Layout variant="hero">
        <HomeHero />
        <Lawyers
          lawyers={homepage?.lawyers}
          title={homepage?.lawyersSectionTitle}
          subtitle={homepage?.lawyersSectionSubtitle}
          image={homepage?.lawyersSectionImage}
        />
        <Stats stats={homepage?.stats || []} title={homepage?.statsTitle} />
        <Skills
          skills={homepage?.skills || []}
          title={homepage?.skillsTitle}
          description={homepage?.skillsDescription}
        />
        <Values values={homepage?.values || []} title={homepage?.valuesTitle} />
        <ArticlesCarousel
          articles={articles}
          title={homepage?.blogSectionTitle}
        />
      </Layout>
    </>
  );
};

export const query = graphql`
  query {
    datoCmsHomepage {
      seo {
        title
        description
      }
      lawyersSectionTitle
      lawyersSectionSubtitle
      lawyersSectionImage {
        url
        alt
        fluid(maxWidth: 1920) {
          ...GatsbyDatoCmsFluid
        }
      }
      lawyers {
        name
        id
        description
      }
      statsTitle
      stats {
        id
        value
        unit
        label
      }
      valuesTitle
      values {
        title
        description
      }
      skillsTitle
      skillsDescription
      skills {
        icon {
          url
        }
        title
        contentNode {
          childMarkdownRemark {
            excerptAst(pruneLength: 140)
          }
        }
      }
      blogSectionTitle
    }

    allDatoCmsArticle(
      limit: 5
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          title
          kicker
          meta {
            firstPublishedAt
          }
          cover {
            url
            fluid(maxHeight: 200) {
              ...GatsbyDatoCmsFluid
            }
          }
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export default Homepage;
